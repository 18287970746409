<template>
  <div id="all-time-dropdown">
    <b-card>
      <div class="row text-right">
        <div class="col-md-3">
          <div class="d-flex flex-row">
            <label for="" class="label-font">Status:</label>
            <button @click="changeStatus(0)" class="btn">
              <span class="active-success"></span>
              <span :class="query.status === 0 ? 'has-border' : ''"
                >Active</span
              >
            </button>
            <button @click="changeStatus(1)" class="btn p-0">
              <span class="active-danger"></span>
              <span :class="query.status === 1 ? 'has-border' : ''"
                >Inactive</span
              >
            </button>
          </div>
        </div>
        <div class="col-md-9 p-0 d-flex align-items-center justify-content-end">
          <a
            v-if="$can('create', 'Employee')"
            href="https://grypas.inflack.xyz/grypas-api/bulk/Employee_Data_Sample.xlsx"
            class="btn mt-1 mt-lg-0 add-btn d-block mr-1"
          >
            <div class="d-flex flex-row align-items-center">
              <span class="pr-1"> Download Sample </span>
              <span>
                <img
                  src="@/assets/images/icons/download-1.png"
                  alt="add icon"
                  height="20"
                  width="20"
                />
              </span>
            </div>
          </a>
          <router-link to="/employee-import" v-if="$can('create', 'Employee')">
            <b-button class="btn mt-1 mt-lg-0 add-btn d-block mr-1">
              <div class="d-flex flex-row align-items-center">
                <span class="pr-1"> Import Employee </span>
                <span>
                  <img
                    src="@/assets/images/icons/upload-1.png"
                    alt="add icon"
                    height="20"
                    width="20"
                  />
                </span>
              </div>
            </b-button>
          </router-link>
          <b-button
            v-if="$can('create', 'Employee')"
            class="btn mt-1 mt-lg-0 add-btn d-block"
          >
            <router-link to="/employee-create">
              <div class="d-flex flex-row align-items-center">
                <span class="pr-1"> Add Employee </span>
                <span>
                  <img
                    src="@/assets/images/icons/add-icon.png"
                    alt="add icon"
                    height="20"
                    width="20"
                  />
                </span>
              </div>
            </router-link>
          </b-button>
        </div>
      </div>
      <div class="row d-flex align-items-center">
        <div class="col-md-12">
          <div class="row">
            <div
              class="col-md-3"
              v-if="logedinUser.roles[0].name === 'superadmin'"
            >
              <label for="" class="label-font">Subsidiary Company:</label>
              <b-form-group>
                <v-select
                  v-model="query.company"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="companies"
                  label="name"
                  placeholder="Select Subsidiary Company"
                  @input="getCustomers"
                />
              </b-form-group>
            </div>
            <div
              class="col-md-3"
              v-if="
                logedinUser.roles[0].name === 'superadmin' ||
                logedinUser.roles[0].name === 'admin'
              "
            >
              <label for="" class="label-font">Client:</label>
              <b-form-group>
                <v-select
                  v-model="query.customer"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="customers"
                  label="name"
                  placeholder="Select Client"
                  @input="getEmployees"
                />
              </b-form-group>
            </div>
            <!-- <div
              class="col-md-3"
              v-if="
                logedinUser.roles[0].name === 'superadmin' ||
                logedinUser.roles[0].name === 'admin'
              "
            >
              <label for="" class="label-font">Expertise:</label>
              <b-form-group>
                <v-select
                  v-model="query.sector"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="sectors"
                  label="name"
                  placeholder="Select Expertise"
                  @input="getEmployees"
                />
              </b-form-group>
            </div> -->
            <div class="col-md-3">
              <label for="" class="label-font">Search:</label>
              <b-form-group>
                <b-form-input
                  v-debounce:300="getEmployees"
                  v-model="query.name"
                  class="rounded search-input bg-light"
                  placeholder="Search..."
                  type="text"
                  size="md"
                />
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </b-card>
    <div>
      <b-card>
        <b-table
          :items="employees"
          :per-page="query.per_page"
          :fields="tableFields"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative shadow-md bg-white"
          :busy.sync="loading"
        >
          <template #cell(sl)="row">
            <div
              :class="`p-1 ${
                row.item.status === 0 && row.item.user_status === 'active'
                  ? 'active-success'
                  : 'active-danger'
              }`"
            >
              <span>
                {{ row.index + from }}
              </span>
            </div>
          </template>
          <template #cell(id)="row">
            <span>
              {{ row.item.id }}
            </span>
          </template>
          <template #cell(name)="row">
            <div v-if="row.item.employee">
              {{ row.item.name ? row.item.name : "N/A" }}
            </div>
          </template>

          <template
            v-if="
              logedinUser.roles[0].name != 'admin' &&
              logedinUser.roles[0].name != 'customer'
            "
            #cell(AssignedBy)="row"
          >
            {{
              row.item.employee_to_company &&
              row.item.employee_to_company.length > 0
                ? getAssignedTo(row.item.employee_to_company)
                : "N/A"
            }}
          </template>
          <template #cell(Email)="row">
            {{ row.item.email }}
          </template>
          <template #cell(Address)="row">
            {{
              row.item.employee && row.item.employee.address
                ? row.item.employee.address
                : "N/A"
            }}
          </template>
          <!-- <template #cell(Expertise)="row">
            {{ row.item.sector ? row.item.sector.name : "N/A" }}
          </template> -->

          <template #cell(action)="row">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                v-if="logedinUser.roles[0].name === 'superadmin'"
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item>
                  <div class="text-center">
                    <b-link
                      v-if="$can('view', 'Employee')"
                      :to="`/employee-preview/${row.item.id}`"
                      class="btn btn-info btn-sm"
                    >
                      View
                    </b-link>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item>
                  <b-button
                    v-if="row.item.status == '0' && $can('suspend', 'Employee')"
                    @click.prevent="suspendUser(row.item.id)"
                    class="btn btn-danger btn-sm mx-1"
                  >
                    Suspend
                  </b-button>
                  <b-button
                    v-if="
                      row.item.status == '1' &&
                      $can('withdrawal suspension', 'Employee')
                    "
                    @click.prevent="activeUser(row.item.id)"
                    class="btn btn-success btn-sm mx-1"
                  >
                    Withdrawal Suspension
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
              <div class="text-center" v-else>
                <b-link
                  v-if="$can('view', 'Employee')"
                  :to="`/employee-preview/${row.item.id}`"
                  class="btn btn-info btn-sm"
                >
                  View
                </b-link>
              </div>
            </span>
          </template>
        </b-table>
        <b-pagination
          class="mt-3 p-1 d-flex justify-content-end bg-white"
          v-if="total > query.per_page"
          v-model="query.page"
          :total-rows="total"
          :per-page="query.per_page"
          aria-controls="my-table"
          @change="getEmployees($event)"
          pills
          size="lg"
          page-class="info"
        ></b-pagination>
      </b-card>
    </div>
  </div>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
import { toast } from "@/utils/toast";
import {
  BAvatar,
  BBadge,
  BButton,
  BCalendar,
  BCard,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  components: {
    BAvatar,
    BBadge,
    BButton,
    BCalendar,
    BCard,
    BCol,
    BContainer,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInput,
    BFormSelect,
    BLink,
    BMedia,
    BPagination,
    BRow,
    BTable,
    BTooltip,
    BFormGroup,
    BPagination,
    vSelect,
  },
  data() {
    return {
      tableFields: [
        { key: "sl", sortable: true },
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        // { key: "AssignedBy", label: "Assigned By" },
        { key: "Email" },
        {
          key: "Address",
        },
        { key: "phone" },
        // { key: "Expertise" },
        { key: "action", label: "Action" },
      ],
      sector: {
        name: "",
      },
      sectors: [],
      employees: [],
      companies: [],
      customers: [],
      loading: false,
      query: {
        keyword: "",
        page: 1,
        per_page: 20,
        name: "",
        sector: null,
        status: 0,
      },
      sortBy: "id",
      isSortDirDesc: true,
      total: 0,
      from: 0,
      logedinUser: JSON.parse(getLogedInUser()),
      loading: false,
    };
  },
  created() {
    this.getCompanies();
    if (this.logedinUser.roles[0].name == "admin") {
      this.query.company_id = this.logedinUser.id;
      this.query.company = this.logedinUser;
      this.getCustomers();
    } else if (this.logedinUser.roles[0].name == "customer") {
      this.query.customer_id = this.logedinUser.id;
      this.query.customer = this.logedinUser;
      this.getCustomers();
    } else {
      this.getEmployees();
    }
    // this.getSectors();
  },
  methods: {
    getSectors() {
      this.$store.dispatch("sector/getSectors", this.query).then((response) => {
        this.sectors = response.data.data;
      });
    },
    getCompanies() {
      this.$store
        .dispatch("company/getCompaniesForDropdown")
        .then(({ data }) => {
          this.companies = data;
        });
    },
    async getCustomers(e) {
      let query = {};
      if (this.query.company && this.query.company.id) {
        query.user_id = this.query.company.id;
      } else {
        query.user_id = "";
      }
      if (query.user_id) {
        await this.$store
          .dispatch("customer/getCustomersForDropdown", query)
          .then((response) => {
            // reassigned the customers to the new array which has the customer_to_company.length > 0 in customer object
            this.customers = response.data;
          });
      }

      this.getEmployees();
    },
    changeStatus(status) {
      this.query.status = status;
      this.query.page = 1;
      this.getEmployees();
    },
    getEmployees(e) {
      this.loading = true;
      if (this.logedinUser.roles[0].name == "admin") {
        this.query.company_id = this.logedinUser.id;
      }
      if (this.logedinUser.roles[0].name == "customer") {
        this.query.customer_id = this.logedinUser.id;
        this.query.company = this.logedinUser.customer_to_company[0];
      }
      if (e && typeof e === "number") {
        this.query.page = e;
      } else {
        this.query.page = 1;
      }
      if (this.query.company && this.query.company.id) {
        this.query.company_id = this.query.company.id;
      } else {
        this.query.company_id = "";
      }
      if (this.query.customer && this.query.customer.id) {
        this.query.customer_id = this.query.customer.id;
      } else {
        this.query.customer_id = "";
      }
      if (this.query.sector && this.query.sector.id) {
        this.query.sector_id = this.query.sector.id;
      } else {
        this.query.sector_id = "";
      }
      // delete company and customer from query
      let company = this.query.company;
      let customer = this.query.customer;
      delete this.query.company;
      delete this.query.customer;

      this.$store
        .dispatch("employee/getEmployees", this.query)
        .then((res) => {
          this.employees = res.data.data;
          this.total = res.data.total;
          this.from = res.data.from;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
      // reassign company and customer to query
      this.query.company = company;
      this.query.customer = customer;
    },

    getAssignedTo(companies) {
      let comps = [];
      let assignedTo = companies.map((company) => {
        if (this.logedinUser.roles[0].name === "customer") {
          if (company.id === this.logedinUser.id) {
            comps.push(company.name);
          }
        } else {
          comps.push(company.name);
        }
      });

      return comps.join(", ");
    },
    suspendUser(item) {
      // statusChange
      let data = {
        id: item,
        status: 1,
      };

      this.$store
        .dispatch("user/statusChange", data)
        .then((response) => {
          if (response.status_code === 200) {
            toast("Suspended", "CheckCircleIcon", "success", "User Suspended.");
            this.getEmployees();
          }
        })
        .catch((error) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            error.response?.data?.message || "Error Suspending User"
          );
        });
    },
    activeUser(item) {
      // statusChange
      let data = {
        id: item,
        status: 0,
      };
      this.$store
        .dispatch("user/statusChange", data)
        .then((response) => {
          if (response.status_code === 200) {
            toast("Activated", "CheckCircleIcon", "success", "User Activated");
            this.getEmployees();
          }
        })
        .catch((error) => {
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            error.response?.data?.message || "Error Activing User"
          );
        });
    },
  },
};
</script>

<style scoped>
.active-indicator {
  background-color: #50c878;
  height: 40px;
  width: 4px;
  padding: 0;
}

.Unengaged-indicator {
  background-color: #ff4f5d;
  height: 40px;
  width: 4px;
}

#indicator-unengaged [dir] .table th,
[dir] .table td {
  padding: 0rem 0rem;
}

#indicator-active [dir] .table th,
[dir] .table td {
  padding: 0rem 0rem;
}

.label-font {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-top: 10px;
}

.active-success {
  border-left: 4px solid #50c878;
  margin-right: 5px;
}

.active-danger {
  border-left: 4px solid #ff4f5d;
  margin-right: 5px;
}

[dir="ltr"] .custom-select {
  /* 14 bold */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  color: #7190ef !important;
}

.add-btn {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.has-border {
  border: 1px solid #7190ef;
  border-radius: 5px;
  padding: 5px 10px;
  margin-left: 5px;
  color: #7190ef;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
